import React from 'react'
import { FormattedMessage } from 'react-intl'

import CloseIcon from '../../../../../assets/images/icon-close.svg'
import SvgImage from '../../components/SvgImage'

const SideHelp = ({ onSideMenuClose, currentLocale, headerLinks }) => {
  return (
    <div className='side-menu'>
      <div className='side-menu__title'>
        <span> <FormattedMessage id='pages.header.need_help' /> </span>
        <SvgImage svg={CloseIcon} onClick={() => onSideMenuClose(null)}></SvgImage>
      </div>

      <a className='side-menu__link' href={headerLinks.howItWorks}><FormattedMessage id='pages.header.how_it_works' /></a>
      <a className='side-menu__link' href={headerLinks.support}><FormattedMessage id='pages.header.help_center' /></a>
      <a className='side-menu__link' href={headerLinks.guide}><FormattedMessage id='pages.header.parking_guide' /></a>
      <a className='side-menu__link side-menu__link--highlighted' href={headerLinks.contact}>
        <FormattedMessage id='pages.header.contact_us' />
      </a>
      {headerLinks.blog && <a className='side-menu__link' href={headerLinks.blog}>Blog</a>}
      {currentLocale === 'fr' && <a className='side-menu__link' href={headerLinks.mobileApplication}>Notre application mobile</a>}
    </div>
  )
}

export default SideHelp
