import React, { Component } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import moment from 'moment'
import classNames from 'classnames'

import { BestOfferAndAmount } from '../Park/BookingPanel/BestOfferAndAmount'
import { ParkCard } from 'Components/ParkCard'

import iconArrow from 'Assets/images/svg-on-react/calendar-arrow-right.svg'
import iconGreenArrow from 'Assets/images/svg-on-react/calendar-arrow-right-hover.svg'
import SvgImage from 'Components/SvgImage'
import { CustomFormattedCurrency } from 'Components/CustomFormattedCurrency'

class ParkOfferSummary extends Component {
  render () {
    const { beginDate, discountCode, endDate, offerBuilder, park, displayPriceBeforeDiscount, showOptionPrice, offerAmount, offerType, showItinerary,
      showMultipleEntriesChoice, localePrefix, offerId, optionsPrice, handleMultipleEntriesChange, isMultipleEntriesChecked, oldPrice,
      paidPriceAlternativeCurrency
    } = this.props
    const oldBeginDate = moment(this.props.oldBeginDate)
    const oldEndDate = moment(this.props.oldEndDate)
    const showOldValues = typeof oldPrice !== 'undefined' && oldPrice !== null

    return (
      <div className={'park-offer-summary'}>
        <div className={'park-offer-summary__bordered-container'}>
          <div className={'park-offer-summary__park-card-container'}>
            <ParkCard park={park} showAddress showItinerary={showItinerary} />
          </div>
          {!showOldValues &&
            <div className={'row park-offer-summary__dates park-offer-summary__dates--margined'}>
              <div className='date'>
                {offerType === 'package' &&
                  <>
                    <FormattedMessage id='purchase.purchase_summary.format_date' >{f => beginDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                    <br />
                    <FormattedMessage id='pages.header.summary.format_hour' >{f => beginDate.tz(park.timezoneName).format(f)}</FormattedMessage>
                  </>
                }
                {offerType === 'subscription' &&
                  <FormattedMessage id='purchase.purchase_summary.subscription.begin_date_label' />
                }
              </div>
              <div className='arrow'>
                <SvgImage svg={iconArrow} />
              </div>
              <div className='date'>
                {offerType === 'package' &&
                  <>
                    <FormattedMessage id='purchase.purchase_summary.format_date' >{f => endDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                    <br />
                    <FormattedMessage id='pages.header.summary.format_hour' >{f => endDate.tz(park.timezoneName).format(f)}</FormattedMessage>
                  </>
                }
                {offerType === 'subscription' &&
                  <>
                    <FormattedMessage id='purchase.purchase_summary.format_date' >{f => beginDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                  </>
                }
              </div>
            </div>
          }
        </div>
        {showOldValues &&
          <div className='park-offer-summary__old-offer park-offer-summary__bordered-container park-offer-summary__bordered-container--grey-top '>
            <div className='park-offer-summary__old-title'>
              <FormattedMessage id='booking.edit.original_offer' />
            </div>
            <div className='park-offer-summary__edit-dates park-offer-summary__edit-dates--old row'>
              <div className='date'>
                {offerType === 'package' &&
                  <>
                    <FormattedMessage id='purchase.purchase_summary.format_date' >{f => oldBeginDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                    <br />
                    <FormattedMessage id='pages.header.summary.format_hour' >{f => oldBeginDate.tz(park.timezoneName).format(f)}</FormattedMessage>
                  </>
                }
                {offerType === 'subscription' &&
                  <FormattedMessage id='purchase.purchase_summary.subscription.begin_date_label' />
                }
              </div>
              <div className='arrow'>
                <SvgImage svg={iconArrow} />
              </div>
              <div className='date'>
                {offerType === 'package' &&
                  <>
                    <FormattedMessage id='purchase.purchase_summary.format_date' >{f => oldEndDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                    <br />
                    <FormattedMessage id='pages.header.summary.format_hour' >{f => oldEndDate.tz(park.timezoneName).format(f)}</FormattedMessage>
                  </>
                }
                {offerType === 'subscription' &&
                  <>
                    <FormattedMessage id='purchase.purchase_summary.format_date' >{f => oldBeginDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                  </>
                }
              </div>
            </div>
            <div>
              <div className='park-offer-summary__old-amount'>
                <FormattedMessage id='booking.edit.reimbursement' />
                <span>
                  {paidPriceAlternativeCurrency &&
                    CustomFormattedCurrency({
                      currency: paidPriceAlternativeCurrency.unit,
                      value: paidPriceAlternativeCurrency.total
                    })}
                  &nbsp;
                  <FormattedNumber value={oldPrice} style='currency' currency='EUR' />
                  {offerType === 'subscription' && <FormattedMessage id='pages.park.frequency' />}
                </span>
              </div>
            </div>
          </div>
        }

        <div className={classNames({ 'park-offer-summary__bordered-container park-offer-summary__bordered-container--green-top': (showOldValues) })}>
          {showOldValues &&
            <>
              <div className='park-offer-summary__old-title'>
                <FormattedMessage id='booking.edit.new_offer_title' />
              </div>

              <div className={classNames('row', {
                'park-offer-summary__dates': (!showOldValues),
                'park-offer-summary__edit-dates': (showOldValues),
                'park-offer-summary__edit-dates--new': (showOldValues)
              })}>
                <div className='date'>
                  {offerType === 'package' &&
                    <>
                      <FormattedMessage id='purchase.purchase_summary.format_date' >{f => beginDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                      <br />
                      <FormattedMessage id='pages.header.summary.format_hour' >{f => beginDate.tz(park.timezoneName).format(f)}</FormattedMessage>
                    </>
                  }
                  {offerType === 'subscription' &&
                    <FormattedMessage id='purchase.purchase_summary.subscription.begin_date_label' />
                  }
                </div>
                <div className='arrow'>
                  <SvgImage svg={showOldValues ? iconGreenArrow : iconArrow} />
                </div>
                <div className='date'>
                  {offerType === 'package' &&
                    <>
                      <FormattedMessage id='purchase.purchase_summary.format_date' >{f => endDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                      <br />
                      <FormattedMessage id='pages.header.summary.format_hour' >{f => endDate.tz(park.timezoneName).format(f)}</FormattedMessage>
                    </>
                  }
                  {offerType === 'subscription' &&
                    <>
                      <FormattedMessage id='purchase.purchase_summary.format_date' >{f => beginDate.tz(park.timezoneName).format(f).toUpperCase()}</FormattedMessage>
                    </>
                  }
                </div>
              </div>
            </>
          }

          <div className={classNames('park-offer-summary__details', { 'park-offer-summary__details--borderless': (showOldValues) })}>
            <BestOfferAndAmount onSitePrice={park.onSitePrice} parkBestOffer={offerBuilder} discountCode={discountCode} purchasePage
              displayPriceBeforeDiscount={displayPriceBeforeDiscount} showOptionPrice={showOptionPrice} offerAmount={offerAmount} bigTotal
              showMultipleEntriesChoice={showMultipleEntriesChoice} localePrefix={localePrefix} offerId={offerId} optionsPrice={optionsPrice}
              handleMultipleEntriesChange={handleMultipleEntriesChange} isMultipleEntriesChecked={isMultipleEntriesChecked} showNewAmount={showOldValues}
              totalAmountSectionClasses='amount__price-container--with-centered-items' toolTipBemModifier='payment-pages-sourced'
            />
          </div>
        </div >
      </div >
    )
  }
}

export default ParkOfferSummary
