import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Collapsible from 'react-collapsible'

import SvgImage from '../../components/SvgImage'
import iconEdit from 'Assets/images/svg-on-react/icon-pencil.svg'
import { EDIT_URL_KEY } from '../../constants/application'

class AccountSection extends Component {
  componentDidMount () {
    sessionStorage.removeItem(EDIT_URL_KEY)
  }

  handleEditBillingInfo = () => {
    const { localePrefix, offerBuilder } = this.props
    if (this.props.saveUrl) { sessionStorage.setItem(EDIT_URL_KEY, window.location.href) }

    window.location = `${localePrefix}/member/billing-informations?offer_id=${offerBuilder.id}&action=edit&origin=booking`
  }

  render () {
    const { user } = this.props

    return (
      <div className='purchase-summary__block row'>
        <FormattedMessage id='purchase.purchase_summary.account.title'>
          {(message) => (
            <Collapsible trigger={message} >
              <div className='purchase-summary__info-block purchase-summary__info-block--limited-width'>
                <h4><FormattedMessage id='purchase.purchase_summary.account.email_title' /></h4>
                <p className='purchase-summary__email'>{user.email}</p>
              </div>
              <div className='purchase-summary__block--flex'>
                <div className='purchase-summary__info-block'>
                  <h4><FormattedMessage id='purchase.purchase_summary.account.address_title' /></h4>
                  {user.company && <p className='purchase-summary__info-element'>{user.company.name}</p>}
                  <p className='purchase-summary__info-element'>{user.name}</p>
                  <p className='purchase-summary__info-element'>{user.address}</p>
                  <p className='purchase-summary__info-element'>{user.zipCode} {user.city}</p>
                  <p>{user.mobilePhone}</p>
                </div>
                <div onClick={this.handleEditBillingInfo} data-automation-id='purchase-summary__account-edit'>
                  <SvgImage svg={iconEdit} className='svg-image--clickable' />
                </div>
              </div>
            </Collapsible>
          )}
        </FormattedMessage>
      </div>
    )
  }
}

export default AccountSection
