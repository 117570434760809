import React, { Component } from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'

import TooltipComponent from 'Components/TooltipComponent'
import * as PurchaseActions from 'Actions/PurchaseActions'

class MultipleEntriesSection extends Component {
  state = {
    multipleEntries: false
  }

  componentDidMount () {
    this.setState({ multipleEntries: this.props.isMultipleEntriesChecked })
  }

  handleChangeMultipleEntries = async (e) => {
    const { localePrefix, offerId, discountCode } = this.props

    this.setState({ multipleEntries: e.target.checked })
    this.props.handleMultipleEntriesChange(e)

    const discountCodeParams = discountCode ? `&discount_code=${discountCode.code}` : ''

    const res = await fetch(`${localePrefix}/purchases/summary?offer_id=${offerId}&multiple_entries=${e.target.checked}${discountCodeParams}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    const json = await res.json()

    this.props.actions.updateOfferBuilder({ ...json.offerBuilder })
  }

  render () {
    const { multipleEntries } = this.state
    const { optionsPrice } = this.props

    return (
      <div className='multiple-entries-section'>
        <div className={
          classNames('multiple-entries-section__title', {
            'multiple-entries-section__title--active': multipleEntries
          })
        }>
          <div className='multiple-entries-section__checkbox'>
            <input id='multiple_entries'
              className='checkbox-op'
              type='checkbox'
              checked={multipleEntries}
              onChange={this.handleChangeMultipleEntries} />
            <label htmlFor='multiple_entries'>
              {
                !multipleEntries &&
                <FormattedMessage id='purchase.purchase_summary.multiple_entries' />
              }
              {
                multipleEntries &&
                <FormattedMessage id='purchase.purchase_summary.multiple_entries_selected' />
              }
            </label>
          </div>
          <div className='multiple-entries-section__tooltip'>
            <TooltipComponent
              isOpen={false}
              message='pages.park.booking_panel.multiple_entries_option_tooltip' />
          </div>
        </div>
        <div className='multiple-entries-section__price'>
          {
            multipleEntries &&
            <span><FormattedNumber value={optionsPrice} style='currency' currency='EUR' /></span>
          }
        </div>
      </div >
    )
  }
}

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...PurchaseActions }, dispatch) })

export default connect(null, mapDispatchToProps)(MultipleEntriesSection)
