import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl'
import Loader from 'Components/Loader'
import { loadStripe } from '@stripe/stripe-js'
import { datalayerPushGTM, datalayerPush, datalayerPushTransaction } from 'Helpers/application'
import { moveToConfirmationPage, translatedErrorMessage } from 'Helpers/purchaseTunnel'

import ParkOfferSummary from '../Purchase/ParkOfferSummary'

import AccountSection from '../Purchase/Summary/AccountSection'
import TravelInformationsSection from '../Purchase/Summary/TravelInformationsSection'
import DiscountPartnerSection from '../Purchase/Summary/DiscountPartnerSection'
import PaymentSection from '../Purchase/Summary/PaymentSection'
import { Warning } from '../Purchase/Summary/Warning'
import { SummaryReassurancePanel } from '../Purchase/Summary/SummaryReassurancePanel'
import { DuplicateBookingModal } from '../Purchase/DuplicateBookingModal'

import { Info3dSecureDialog } from 'Components/Info3dSecureDialog'
import { OnPurchase3dSecureErrorDialog } from 'Components/OnPurchase3dSecureErrorDialog'
import { OnRegistration3dSecureErrorDialog } from 'Components/OnRegistration3dSecureErrorDialog'
import { canEditTravelInfoInitial, scrollToElementWithFooterAdjustment } from 'Helpers/form'
import { bookingDuplicateCheck } from 'Helpers/api'
import RegistrationBreadcrumb from '../Authentication/Registration/components/RegistrationBreadcrumb'

class EditChangesComponent extends Component {
  storagePrefix = window === undefined ? 'nonexisting-prefix' : window.location.pathname

  locallyStored = () => {
    return {
      acceptTerms: sessionStorage.getItem(`${this.storagePrefix}-acceptTerms`) === 'true',
      professionalUse: sessionStorage.getItem(`${this.storagePrefix}-professionalUse`) === 'true',
      offerAmount: parseFloat(sessionStorage.getItem(`${this.storagePrefix}-offerAmount`)) || 0
    }
  }

  isMultipleEntriesParamSet = () => {
    const valueFromStorage = sessionStorage.getItem(`${this.storagePrefix}-isMultipleEntriesOption`)
    if (valueFromStorage !== null) return (sessionStorage.getItem(`${this.storagePrefix}-isMultipleEntriesOption`) === 'true')

    const urlParams = new URLSearchParams(location.search)
    return urlParams.get('multiple_entries') === 'true'
  }

  state = {
    ...this.locallyStored(),
    isMultipleEntriesOption: this.isMultipleEntriesParamSet(),
    showPaymentForm: !this.props.user.billingIdentity,
    userConsent: this.props.userConsent,
    purchaseError: false,
    errorWithDateRedirect: false,
    threedsError: false,
    loading: false,
    travelInfoEditing: canEditTravelInfoInitial(this.props.travelInfos, this.props.mandatoryInfos),
    stripe: null,
    warningVisible: false,
    summaryError: '',
    highlightTerms: false,
    stripeCompleted: false,
    stripeError: false
  }

  travelInfoSectionRef = React.createRef()
  paymentSectionRef = React.createRef()
  travelFormRef = React.createRef()
  footerRef = React.createRef()
  stripeErrorRef = React.createRef()
  threedsErrorRef = React.createRef()

  sectionRefs = {
    travelInformationSection: this.travelInfoSectionRef,
    paymentSection: this.paymentSectionRef
  }

  travelInfoEditModeSwitcher = (value) => {
    this.setState({ travelInfoEditing: value })
  }

  protectFromReload = (name, value) => {
    if (window === undefined) return

    sessionStorage.setItem(`${this.storagePrefix}-${name}`, value.toString())
  }

  componentDidMount = async () => {
    const stripe = await loadStripe(this.props.stripePublicKey, { apiVersion: '2022-11-15' })
    this.setState({ stripe })
  }

  highlightSection = (section, messageId) => {
    scrollToElementWithFooterAdjustment(this.sectionRefs[section].current, this.footerRef.current)
    if (!messageId) return

    this.toggleWarning(true)
    this.setState({ summaryError: messageId })
  }

  toggleWarning = (value) => {
    this.setState({ warningVisible: value })
  }

  highlightMissingTerms = () => {
    this.setState({ highlightTerms: true })
  }

  checkDuplicateBooking = () => {
    const { localePrefix, offerBuilder: { park: { id: parkId } }, originalBookingId, travelInfos: { license_plate: licensePlate } } = this.props
    const url = `${localePrefix}/bookings/check_duplicated?park_id=${parkId}&original_booking_id=${originalBookingId}&license_plate=${licensePlate}`
    const switchOffLoading = () => this.setState({ loading: false })
    const setDuplicateType = (type) => this.setState({ duplicateType: type })

    bookingDuplicateCheck(url, switchOffLoading, setDuplicateType, this.completeBooking)
  }

  completeBooking = async () => {
    const { userConsent, mobilePurchaseTunnelPaymentInside, history, offerBuilder, beginDate, endDate, renewalDate, travelInfos,
      discountCode, localePrefix, user, partnerCard, originalBookingId, changeId, type } = this.props

    if (!user.billingIdentity && !mobilePurchaseTunnelPaymentInside) {
      history.push({ pathname: 'payment-informations', search: `?offer_id=${offerBuilder.id}` })
      window.scrollTo(0, 0)
      this.setState({ loading: false })
      return
    }
    const res = await fetch(`${localePrefix}/offers/${offerBuilder.id}/purchases`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        park_id: offerBuilder.park_id,
        price: offerBuilder.amount.bookingAmount,
        begin_date: beginDate.toISOString(true),
        end_date: endDate.toISOString(true),
        original_booking_id: originalBookingId,
        booking_change_id: changeId,
        ...travelInfos,
        discount_code: discountCode ? discountCode.code : null,
        partner_loyalty_number: partnerCard ? partnerCard.cardNumber : null,
        partner: partnerCard ? partnerCard.id : null,
        multiple_entries: offerBuilder.multipleEntries.allowSelected,
        personal_data_com: userConsent,
        renewal_date: renewalDate,
        professional: this.state.professionalUse
      })
    })
    const json = await res.json()
    const pushParamsOnError = this.gtmPushParamsOnError(json)

    if (json.error || !json.payment_intent) {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/error', json.error || 'no payment_intent')
      datalayerPush(pushParamsOnError)
      this.setState({ purchaseError: json.error, loading: false, threedsError: false, errorWithDateRedirect: json.error_with_date_redirect })

      this.highlightSection('paymentSection')

      return
    }

    if (json.payment_intent.status === 'succeeded') {
      const purchaseResult = await this.proceedPurchase(json)

      if (purchaseResult) {
        moveToConfirmationPage(purchaseResult, history, localePrefix, type)
      }
      return
    }

    if (json.payment_intent.status === 'requires_action') {
      let purchaseResult

      try {
        const stripeResponse = await this.state.stripe.handleNextAction({ clientSecret: json.payment_intent.client_secret })

        if (stripeResponse && stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
          purchaseResult = await this.proceedPurchase(json)
        } else if (stripeResponse.error && stripeResponse.error.code === 'payment_intent_authentication_failure') {
          this.setState({ threedsError: true, purchaseError: false, stripeError: false, errorWithDateRedirect: false })
          this.threedsErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          throw new Error(stripeResponse.error.message)
        } else {
          this.setState({
            threedsError: false,
            purchaseError: false,
            errorWithDateRedirect: false,
            stripeError: translatedErrorMessage(stripeResponse.error, this.props.intl)
          })
          this.stripeErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          throw new Error(stripeResponse.error.message)
        }
      } catch {
        this.setState({ loading: false })
        await this.cancelTransaction(json.billable_id)
        datalayerPush(pushParamsOnError)
      }

      if (purchaseResult) {
        moveToConfirmationPage(purchaseResult, history, localePrefix, type)
      }
    }
  }

  handleSubmitPurchase = event => {
    const { acceptTerms, travelInfoEditing, showPaymentForm } = this.state

    if (event) { event.preventDefault() }

    if (!acceptTerms) { return this.highlightMissingTerms() }

    const travelInfoIsValid = this.travelFormRef.current && this.travelFormRef.current.allFieldsAreValid()
    if (this.travelFormRef.current && ((travelInfoEditing && travelInfoIsValid) || !travelInfoIsValid)) {
      this.highlightSection('travelInformationSection', 'purchase.purchase_summary.warning_vehicle_travel')
      this.setState({ travelWarning: true })
      scrollToElementWithFooterAdjustment(this.travelFormRef.current, this.footerRef.current)
      return
    }

    if (showPaymentForm) {
      this.setState({ paymentWarning: true })
      this.highlightSection('paymentSection', 'purchase.purchase_summary.warning_payment')

      const stripeSubmitButton = document.getElementById('payment-details-submit-button')
      if (!this.state.stripeCompleted && stripeSubmitButton) { stripeSubmitButton.click() }

      return
    }

    this.toggleWarning(false)
    this.setState({ loading: true })
    this.checkDuplicateBooking()
  }

  gtmPushParamsOnError = (json) => {
    const park = (json.purchase && json.purchase.park) || {}
    const { offerBuilder } = this.props
    const pushParams = {
      event: 'payment_error',
      ep_vehicle_type: '4W',
      ep_new_customer: `${json.new_customer}`,
      currency: 'EUR',
      value: (offerBuilder.multipleEntries.allowSelected ? offerBuilder.amount.totalWithOptions : offerBuilder.amount.totalWithoutOptions),
      transaction_id: `${json.purchase && json.purchase.booking && json.purchase.booking.id}`,
      item_id: `${park.id}`,
      item_name: park.name,
      up_language: (window._oneparkDatas.up_language || null),
      up_locale: (window._oneparkDatas.up_locale || null)
    }
    if (window._oneparkDatas.up_user_id != null && window._oneparkDatas.up_user_id.length !== 0) pushParams.up_user_id = window._oneparkDatas.up_user_id
    return pushParams
  }

  proceedPurchase = async (json) => {
    const { localePrefix, user } = this.props
    const transactionResult = await fetch(`${localePrefix}/payment/transactions/${json.billable_id}`, {
      method: 'PATCH',
      body: JSON.stringify({ payment_intent_id: json.payment_intent && json.payment_intent.id }),
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    const transactionJson = await transactionResult.json()
    if (transactionResult.ok && transactionJson.purchase) {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/success', transactionJson.purchase.id)
      if (window.Cookies.get('new_purchase_confirmation') !== undefined) {
        datalayerPushTransaction(transactionJson.purchase)
        datalayerPushGTM('serverEventTransaction')
        window.ga('send', 'event', 'purchase', 'transaction/success', user.id)
      }
      persistStore(this.props).purge()

      window.scrollTo(0, 0)

      return transactionJson
    } else {
      datalayerPushGTM('form-tracking', 'Users', 'purchase/error', transactionJson.error)
      datalayerPush(this.gtmPushParamsOnError(json))

      this.setState({ purchaseError: transactionJson.error, errorWithDateRedirect: transactionJson.error_with_date_redirect })
      this.setState({ loading: false })

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth', block: 'start' })

      return false
    }
  }

  cancelTransaction = async (transactionId) => {
    const { localePrefix } = this.props

    fetch(`${localePrefix}/payment/transactions/${transactionId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })

    this.setState({ loading: false })
  }

  handleMultipleEntriesChange = (event) => {
    const optionPrice = parseFloat(this.props.offerBuilder.multipleEntries.optionPrice)
    const newOfferAmount = this.getOfferAmount() + (event.target.checked ? optionPrice : -optionPrice)

    this.setState({ isMultipleEntriesOption: event.target.checked, offerAmount: newOfferAmount })
    this.protectFromReload('isMultipleEntriesOption', event.target.checked)
    this.protectFromReload('offerAmount', newOfferAmount)
  }

  getOfferAmount = () => {
    return parseFloat(this.state.offerAmount) || parseFloat(this.props.offerBuilder.amount.totalWithOptions)
  }

  handleShowPaymentForm = () => {
    const { showPaymentForm } = this.state
    this.setState({ showPaymentForm: !showPaymentForm })
  }

  renderTermsLink (type, path) {
    return (
      <a href={path} target='_blank' className='link--secondary'>
        <FormattedMessage id={`purchase.purchase_summary.${type}`} />
      </a>
    )
  }

  goBackToPurchase = () => {
    this.setState({ loading: true }, () => { window.location.href = `${this.props.localePrefix}/purchases/${this.props.originalPurchaseId}` })
  }

  show3dsInfo = (event) => { event.preventDefault(); $('#info-3d-secure').modal('show') }

  render () {
    const { beginDate, discountCode, salesTermsPath, termsPath, endDate, intl, localePrefix, mandatoryInfos,
      offerBuilder, travelInfos, user, provider, mobilePurchaseTunnelPaymentInside, mobilePurchaseTunnel,
      optinDisplaying, partnerName, isDefaultHost, type, renewalDate, oldBeginAt, oldEndAt, oldAmount } = this.props
    const { acceptTerms, showPaymentForm, purchaseError, threedsError, professionalUse, loading, userConsent,
      warningVisible, summaryError, highlightTerms, errorWithDateRedirect, stripeError } = this.state
    const errorParts = purchaseError && errorWithDateRedirect && purchaseError.split('. ')
    const parkUrl = `${localePrefix}/parkings/${offerBuilder.park.id}${type === 'subscription' ? `/?type=${type}` : ''}`

    return (
      <div className='react-component react-component--flex row'>
        <div className='row purchase-container h-100p'>
          <div className='purchase-summary'>
            <OnRegistration3dSecureErrorDialog />
            <OnPurchase3dSecureErrorDialog />
            <Info3dSecureDialog />
            <DuplicateBookingModal
              handleContinue={() => {
                this.setState({ loading: true })
                this.completeBooking()
              }}
              duplicateType={this.state.duplicateType} />

            {warningVisible && <Warning toggleWarning={this.toggleWarning} messageId={summaryError} />}
            <div className='row purchase-summary__header purchase-summary__header--always-display-left'>
              <RegistrationBreadcrumb previousSteps={[]} futureSteps={[]} />
              <hr className='purchase-summary__divider purchase-summary__divider--no-shadow purchase-summary__divider--starting-left-visible' />
            </div>
            <div className='purchase-summary__page-title row'>
              <h1><FormattedMessage id='booking.edit.page_title' /></h1>
            </div>
            {
              errorParts &&
              <div className='row purchase-summary__error-message purchase-summary__error-message--orange purchase-summary__error-message--padded'>
                <p data-automation-id='purchase-summary__payment-error'>
                  <span role='alert'>
                    {`${errorParts[0]}. `}
                  </span>
                  <a href={parkUrl} target='_blank' className='purchase-summary__link'>
                    {errorParts[1]}
                  </a>
                </p>
              </div>
            }

            <div className='row'>
              <div className='purchase-summary__summary-section col-xs-12 col-sm-4 col-sm-push-8'>
                <div className='row'>
                  <div className='purchase-summary__offer'>
                    <div className='purchase-summary__offer-title'>
                      <FormattedMessage id='booking.edit.summary_title' />
                    </div>
                    <ParkOfferSummary
                      beginDate={beginDate}
                      endDate={endDate}
                      offerBuilder={offerBuilder}
                      park={offerBuilder.park}
                      discountCode={discountCode}
                      showOptionPrice={this.state.isMultipleEntriesOption}
                      offerAmount={this.getOfferAmount()}
                      offerType={type}
                      localePrefix={localePrefix}
                      offerId={offerBuilder.id}
                      optionsPrice={offerBuilder.multipleEntries.optionPrice}
                      handleMultipleEntriesChange={this.handleMultipleEntriesChange}
                      isMultipleEntriesChecked={this.isMultipleEntriesParamSet()}
                      showMultipleEntriesChoice={offerBuilder.multipleEntries.allow}
                      oldBeginDate={oldBeginAt}
                      oldEndDate={oldEndAt}
                      paidPriceAlternativeCurrency={this.props.paidPriceAlternativeCurrency}
                      oldPrice={oldAmount} />

                    <div className='purchase-summary__reassurance-panel'>
                      <SummaryReassurancePanel
                        maxCancellationDelay={offerBuilder.maxCancellationDelay}
                        maxModificationDelay={offerBuilder.maxModificationDelay}
                        renewalDate={renewalDate}
                        isSubscription={type === 'subscription'}
                        intl={intl} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='purchase-summary__editable-section col-xs-12 col-sm-8 col-sm-pull-4'>
                <div className='row purchase-summary__information-section'>
                  <FormattedHTMLMessage id='booking.edit.validate_order_desc' />
                </div>
                <DiscountPartnerSection />
                <TravelInformationsSection
                  localePrefix={localePrefix}
                  mandatoryInfos={mandatoryInfos}
                  offerBuilder={offerBuilder}
                  editMode={this.state.travelInfoEditing}
                  travelInfoEditModeSwitcher={this.travelInfoEditModeSwitcher}
                  storagePrefix={this.storagePrefix}
                  travelInfos={this.storedTravelInfos ? JSON.parse(this.storedTravelInfos) : travelInfos}
                  forwardedRef={this.travelInfoSectionRef}
                  formRef={this.travelFormRef}
                  warning={this.state.travelWarning}
                  parkTimezone={offerBuilder.park.timezoneName}
                  protectFromReload={this.protectFromReload}
                  turnoffWarning={() => this.setState({ travelWarning: false })} />
                <AccountSection
                  localePrefix={localePrefix}
                  user={user}
                  offerBuilder={offerBuilder}
                  saveUrl />
                <PaymentSection
                  mobilePurchaseTunnelPaymentInside={mobilePurchaseTunnelPaymentInside}
                  mobilePurchaseTunnel={mobilePurchaseTunnel}
                  billingIdentity={user.billingIdentity}
                  offerBuilder={offerBuilder}
                  localePrefix={localePrefix}
                  handleShowPaymentForm={this.handleShowPaymentForm}
                  showPaymentForm={showPaymentForm}
                  handleSubmitPurchase={this.handleSubmitPurchase}
                  stripePublicKey={this.props.stripePublicKey}
                  provider={provider}
                  forwardedRef={this.paymentSectionRef}
                  warning={this.state.paymentWarning}
                  turnoffWarning={() => this.setState({ paymentWarning: false })}
                  handleStripeChange={(complete) => this.setState({ stripeCompleted: complete })}
                  handleStripeError={() => this.setState({
                    threedsError: false,
                    purchaseError: false,
                    errorWithDateRedirect: false
                  })}
                  green
                />

                {
                  stripeError &&
                  <div className='row purchase-summary__error-message purchase-summary__error-message--orange' ref={this.stripeErrorRef}>
                    <p data-automation-id='purchase-summary__payment-error' className='purchase-summary__payment-error'>
                      {stripeError}
                    </p>
                  </div>
                }

                {user.billingIdentity &&
                  <>
                    <div className='row'>
                      {threedsError && !purchaseError &&
                        <div className='purchase-summary__error-message purchase-summary__error-message--orange' ref={this.threedsErrorRef}>
                          <p data-automation-id='purchase-summary__threeds-error' className='purchase-summary__threeds-error'>
                            <small role='alert'>
                              <FormattedMessage
                                id='authentication.registration.payment_info.3ds.error_message.payment_before_link' />
                              <a className='secure-3d-link' onClick={show3dsErrorOnPurchase}>
                                <FormattedMessage
                                  id='authentication.registration.payment_info.3ds.error_message.link_title' />
                              </a>
                            </small>
                          </p>
                        </div>
                      }

                      <div>
                        {!threedsError && !purchaseError &&
                          <p className='purchase-summary__3ds-text'>
                            <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.before_link' />
                            <a href={'#'} onClick={this.show3dsInfo}>
                              <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.link_text' />
                            </a>
                            <FormattedMessage id='purchase.3d_secure_popup_dialog.mobile.3d_secure_informative_sentence.after_link' />
                          </p>}
                      </div>

                      {purchaseError && !errorWithDateRedirect &&
                        <div className='row purchase-summary__error-message purchase-summary__error-message--orange purchase-summary__error-message--padded'>
                          <p data-automation-id='purchase-summary__payment-error' className='purchase-summary__payment-error'>
                            {purchaseError}
                          </p>
                        </div>
                      }

                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          {user.billingIdentity &&
            <>
              <div className='purchase-summary__button-container' ref={this.footerRef}>
                <hr className='purchase-summary__divider' />

                <div className='purchase-summary__checks-wrapper'>
                  <div className='purchase-summary__professional-use'>
                    <div className='purchase-summary__checkbox'>
                      <input id='professional_use'
                        data-automation-id='purchase-summary__professional-use'
                        className='checkbox-op'
                        type='checkbox'
                        checked={professionalUse}
                        onChange={(event) => {
                          this.setState({ professionalUse: event.target.checked })
                          this.protectFromReload('professionalUse', event.target.checked)
                        }} />
                      <label htmlFor='professional_use'>
                        <FormattedMessage id='purchase.purchase_summary.is_professional_use' />
                      </label>
                    </div>
                  </div>
                  <div className={`purchase-summary__optin_consent ${optinDisplaying ? '' : 'hidden'}`}>
                    <div className='purchase-summary__checkbox'>
                      <input id='optin_consent'
                        data-automation-id='purchase-summary__optin_consent'
                        className='checkbox-op'
                        type='checkbox'
                        checked={userConsent}
                        onChange={(event) => this.setState({ userConsent: event.target.checked })} />
                      <label htmlFor='optin_consent'>
                        <FormattedMessage id='purchase.purchase_summary.accept_providing_personal_info' values={{ partnerName: partnerName }} />
                      </label>
                    </div>
                  </div>

                  <div className='purchase-summary__accept-terms'>
                    <div className='purchase-summary__checkbox'>
                      <input id='accept_terms'
                        data-automation-id='purchase-summary__accept-terms'
                        className='checkbox-op'
                        type='checkbox'
                        checked={acceptTerms}
                        onChange={(event) => {
                          this.setState({ acceptTerms: event.target.checked, highlightTerms: !event.target.checked })
                          this.protectFromReload('acceptTerms', event.target.checked)
                        }} />
                      <label htmlFor='accept_terms'>
                        <FormattedMessage
                          id={`purchase.purchase_summary.${isDefaultHost ? 'france_' : ''}accept_terms`}
                          values={{ salesTerms: this.renderTermsLink('sales_terms', salesTermsPath), terms: this.renderTermsLink('terms', termsPath) }}
                        />
                      </label>
                    </div>
                    {highlightTerms &&
                      <>
                        <label className='purchase-summary__warning--orange' data-automation-id='purchase-summary__terms-warning'>
                          <FormattedMessage id='purchase.purchase_summary.must_accept_terms' />
                        </label>
                      </>}
                  </div>
                </div>
                <div className='purchase-summary__button-wrapper'>
                  {loading &&
                    <div className='booking-information__loader'>
                      <Loader grey />
                    </div>
                  }
                  {!loading &&
                    <>
                      <button
                        type='button'
                        className='purchase-summary__button purchase-summary__button--cancel btn btn-primary registration__btn-form'
                        onClick={this.goBackToPurchase}
                        disabled={loading}
                        data-automation-id='purchase-summary__cancel-button'>
                        <span>
                          <FormattedMessage id={'actions.cancel'} />
                        </span>
                      </button>
                      <button
                        type='submit'
                        className='purchase-summary__button btn btn-primary registration__btn-form'
                        disabled={loading}
                        onClick={this.handleSubmitPurchase}
                        data-automation-id='purchase-summary__save-button'>
                        <span>
                          <FormattedMessage id={'authentication.save'} />
                        </span>
                      </button>
                    </>}
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({
  purchase: {
    purchaseId,
    discountCode,
    mandatoryInfos, travelInfos,
    partnerCard,
    offer: { beginDate, endDate, renewalDate, offerBuilder, oldBeginAt, oldEndAt, oldAmount, paidPriceAlternativeCurrency },
    originalBookingId,
    salesTermsPath
  },
  changeId,
  resources: {
    mobilePurchaseTunnel,
    mobilePurchaseTunnelPaymentInside,
    localePrefix,
    termsPath,
    isDefaultHost
  },
  search: { params: { type } },
  user,
  optinOptions: { userConsent, partnerName, optinDisplaying }
}) => {
  const storagePrefix = window === undefined ? 'nonexisting-path' : window.location.pathname
  const storedTravelInfos = sessionStorage.getItem(`${storagePrefix}-travelInfos`)
  const resultingTravelInfos = storedTravelInfos ? JSON.parse(storedTravelInfos) : travelInfos

  return ({
    mobilePurchaseTunnel,
    mobilePurchaseTunnelPaymentInside,
    isDefaultHost,
    beginDate,
    discountCode,
    salesTermsPath,
    termsPath,
    endDate,
    renewalDate,
    localePrefix,
    mandatoryInfos,
    offerBuilder,
    partnerCard,
    travelInfos: resultingTravelInfos,
    type,
    user,
    userConsent,
    partnerName,
    optinDisplaying,
    changeId,
    originalBookingId,
    originalPurchaseId: purchaseId,
    oldBeginAt,
    oldEndAt,
    oldAmount,
    paidPriceAlternativeCurrency
  })
}

export const EditChanges = withRouter(injectIntl(connect(mapStateToProps)(EditChangesComponent)))
