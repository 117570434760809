import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { datalayerPushGTM, datalayerPush } from 'Helpers/application'
import classNames from 'classnames'

import FormInput from 'Components/Form/FormInput'
import FormInputDateTimePicker from 'Components/Form/FormInputDateTimePicker'
import Loader from 'Components/Loader'
import * as PurchaseActions from 'Actions/PurchaseActions'
import AttributeCheckbox from 'Components/VehicleInformation/AttributeCheckbox'
import { Warning } from 'Components/VehicleInformation/Warning'
import { LicensePlateField } from 'Components/VehicleInformation/LicensePlateField'
import { valueToMomentInTimezone } from 'Helpers/date'

import {
  performValidation,
  genericComingFromValidation,
  genericFlightNumberArrivalValidation,
  genericFlightNumberDepartureValidation,
  genericLicensePlateValidation,
  genericMeetingPlaceArrivalValidation,
  genericMeetingPlaceDepartureValidation,
  genericVehicleModelValidation,
  genericRealTimeArrivalValidation,
  genericPassengerNumberValidation,
  genericPassportNumberValidation,
  getErrorFieldName
} from 'Helpers/formValidations'

import carIcon from 'ClientAssets/images/vehicletypes/car.png'
import motorcycleIcon from 'ClientAssets/images/vehicletypes/motorcycle.png'
import { LICENSE_PLATE_COUNTRIES } from 'Components/VehicleInformation/constants'
import { scrolledToFirstInvalid } from 'Helpers/form'

class TravelInformationsForm extends Component {
  setupDisabledAfter = () => {
    if (this.props.offerEndDate) {
      return valueToMomentInTimezone({ value: this.props.offerEndDate, timezone: this.props.timezone })
    } else if (this.props.endDate) {
      return valueToMomentInTimezone({ value: this.props.endDate, timezone: this.props.timezone })
    }

    return null
  }

  state = {
    loading: false,
    disabledBefore: valueToMomentInTimezone({ value: this.props.beginDate, timezone: this.props.timezone }),
    disabledAfter: this.setupDisabledAfter(),
    country: LICENSE_PLATE_COUNTRIES.find(item => item.id === this.props.travelInfos.license_plate_country_id) || {},
    travelInfoRef: React.createRef(),
    ...this.props.travelInfos
  }

  componentDidMount = () => {
    datalayerPush({ event: 'page_name', page_name: 'travel_informations' })
    datalayerPushGTM('hitVirtualPageView')
  }

  handleSet = (name, value) => {
    this.setState({ [name]: value })
  }

  handleSetLicensePlate = (name, value) => {
    const snakeCasedName = name.replace(/[A-Z]/g, match => '_' + match.toLowerCase())

    this.handleSet(snakeCasedName, value)
  }

  handleLicensePlateValidation = (value) => this.validateField('license_plate', value)

  fieldValidations = () => this.getFieldsToValidate().map(name => [ name, this.validateField(name, this.state[name]) ])

  allFieldsAreValid = () => this.fieldValidations().every(nameValidTuple => nameValidTuple[1])

  handleSubmit = async (event) => {
    event.preventDefault()

    if (scrolledToFirstInvalid(this.fieldValidations())) return

    const travelInfosAccumulated = {
      license_plate: this.state.license_plate,
      vehicle_model: this.state.vehicle_model,
      electric_vehicle: this.state.electric_vehicle,
      motorcycle: this.state.motorcycle,
      motorcycleMayChange: this.state.motorcycleMayChange,
      isUnknownLicensePlateDisabled: this.state.isUnknownLicensePlateDisabled,
      unknown_license_plate: this.state.unknown_license_plate,
      license_plate_country_id: this.state.country.id,
      ...this.props.mandatoryInfos.reduce((accumulator, { name }) => {
        accumulator[name] = this.state[name]
        return accumulator
      }, {})
    }
    await this.props.actions.updateTravelInfos(travelInfosAccumulated)
    this.props.protectFromReload('travelInfos', JSON.stringify(travelInfosAccumulated))

    datalayerPushGTM('form-tracking', 'Users', 'travel-informations/success', this.props.user.id)
    this.props.disableEditMode()
    this.props.turnoffWarning()
  }

  validateField = (name, value) => {
    if (name === 'license_plate') {
      return this.licenseRef.current.validate()
    }

    const validator = validatorByName[name]

    return !validator || performValidation.call(this, validator, value, getErrorFieldName(name))
  }

  handleCountryChange = (newCountry) => {
    this.setState({ country: newCountry }, () => {
      this.licenseRef.current.validate()
    })
  }

  getFieldsToValidate = () => {
    const fields = ['vehicle_model', ...this.props.mandatoryInfos.map(({ name }) => name)]

    if (!this.state.unknown_license_plate && LICENSE_PLATE_COUNTRIES.some(({ id }) => id === this.state.country.id)) {
      fields.push('license_plate')
    }

    return fields
  }

  renderDateTimePicker ({ name }) {
    const { timezone } = this.props
    const { disabledBefore, disabledAfter } = this.state
    const modifierName = name.split('_').join('-').toLowerCase()

    return (
      <div className={`travel-info-form__date-time-picker travel-info-form__date-time-picker--${modifierName}`}>
        <FormInputDateTimePicker
          id={`${name}Field`}
          value={this.state[name]}
          errorMessage={this.state[getErrorFieldName(name)]}
          railsContext={this.props.railsContext}
          required
          setCallback={(value) => this.handleSet(name, value)}
          validateCallback={(value) => this.validateField(name, value)}
          placeholderDateKey='purchase.travel_informations.attributes.real_time_arrival_date'
          placeholderTimeKey='purchase.travel_informations.attributes.real_time_arrival_time'
          disabledBefore={disabledBefore}
          disabledAfter={disabledAfter}
          timezone={timezone}
        />
      </div>
    )
  }

  renderField ({ id, name, iconLeft, labelId, inputType, uppercase }) {
    const modifierName = name.split('_').join('-').toLowerCase()
    const inputClassNames = classNames('travel-info-form__input', `travel-info-form__input--${modifierName}`,
      {
        'travel-info-form__input--with-icon': iconLeft
      })

    return (
      <div className={inputClassNames}>
        <FormInput
          id={id || `${name}Field`}
          setCallback={(value) => this.handleSet(name, value)}
          validateCallback={(value) => this.validateField(name, value)}
          inputValue={this.state[name] || ''}
          errorMessage={this.state[getErrorFieldName(name)]}
          iconLeft={iconLeft}
          required
          placeholderKey={`purchase.travel_informations.attributes.${labelId}`}
          uppercase={uppercase || false}
        >
          {<input type={inputType || 'text'} className='form-control' />}
        </FormInput>
      </div>
    )
  }

  renderSelect ({ name, labelId, options }) {
    const modifierName = name.split('_').join('-').toLowerCase()
    const wrapperId = `${name}__select-wrapper`

    return (
      <div className={`travel-info-form__select travel-info-form__select--${modifierName}`} id={wrapperId} >
        <FormInput
          id={`${name}Field`}
          type='select-op'
          setCallback={(value) => this.handleSet(name, value)}
          validateCallback={(value) => this.validateField(name, value)}
          inputValue={this.state[name]}
          errorMessage={this.state[getErrorFieldName(name)]}
          required
          placeholderKey={`purchase.travel_informations.attributes.${labelId}`}
        >
          <Select
            className='select-op travel-info-form__select'
            clearable={false}
            searchable={false}
            simpleValue
            placeholder=''
            value={this.state[name]}
            options={options}
            onOpen={() => document.getElementById(wrapperId).scrollIntoView({ behavior: 'smooth', block: 'center' })}
          />
        </FormInput>
      </div>
    )
  }

  licenseRef = React.createRef()

  buttonTextId () {
    const urlParams = new URLSearchParams(this.props.railsContext.location.split('?')[1])
    const editMode = urlParams.get('action') === 'edit'
    if (this.props.disableEditMode) { return 'authentication.save' }
    return editMode ? 'authentication.validate' : 'authentication.continue'
  }

  render () {
    const { mandatoryInfos, meetingPlaces, licensePlateRecognition } = this.props
    const { loading, country, electric_vehicle: electricVehicle, unknown_license_plate: unknownLicensePlate, motorcycle, motorcycleMayChange, isUnknownLicensePlateDisabled } = this.state

    const vehicleModelIcon = motorcycle ? motorcycleIcon : carIcon
    const displayEmptyLicensePlateWarning = !isUnknownLicensePlateDisabled && licensePlateRecognition &&
      (this.state.unknown_license_plate || country.id === null)

    return (
      <>
        <div className='registration-travel-info__intro'>
          <p className='registration-travel-info__text'><FormattedMessage id='purchase.travel_informations.intro' /></p>
          {isUnknownLicensePlateDisabled && (
            <div
              className='vehicle-card__warning vehicle-card__warning--unknown'
              dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: 'vehicle_info.plate_recognition_system_warning' }) }}
            />
          )}
        </div>

        <div className='travel-info-form' ref={this.state.travelInfoRef}>
          <form onSubmit={this.handleSubmit}>
            <div className='travel-info-form__chechboxes-wrapper'>
              <AttributeCheckbox
                attributeName={'electric_vehicle'}
                isChecked={electricVehicle}
                containerRef={this.state.travelInfoRef}
                handleCheckboxChange={this.handleSet}
                showTooltip
              />
              <AttributeCheckbox
                attributeName={'motorcycle'}
                isChecked={!!motorcycle}
                handleCheckboxChange={this.handleSet}
                readonly={!motorcycleMayChange}
                containerRef={this.state.travelInfoRef}
                showTooltip
              />
              <AttributeCheckbox
                attributeName={'unknown_license_plate'}
                isChecked={unknownLicensePlate}
                handleCheckboxChange={this.handleSet}
                readonly={isUnknownLicensePlateDisabled}
              />
            </div>
            { displayEmptyLicensePlateWarning && <Warning intl={this.props.intl} /> }

            <div className='responsive-fields-container'>
              <div className='responsive-fields-container__field'>
                {this.renderField({ id: 'vehicleModelField', name: 'vehicle_model', iconLeft: vehicleModelIcon, labelId: 'vehicle_model' })}
              </div>
              <div className='responsive-fields-container__field'>

                <LicensePlateField
                  licensePlate={this.state.license_plate}
                  unknownLicensePlate={unknownLicensePlate}
                  country={country}
                  handleValidate={this.handleLicensePlateValidation}
                  handleCountryChange={this.handleCountryChange}
                  handleSet={this.handleSetLicensePlate}
                  errorMessage={this.state.license_plateError}
                  ref={this.licenseRef}
                  paddedModal
                />
              </div>

              {mandatoryInfos.map(({ name, type }) => (
                <div key={name} className='responsive-fields-container__field'>
                  {type === 'select' &&
                    this.renderSelect({ name, labelId: name, options: meetingPlaces.map((name, index) => ({ label: name, value: name })) })
                  }
                  {type === 'datetime_picker' &&
                    this.renderDateTimePicker({ name })
                  }
                  {type !== 'datetime_picker' && type !== 'select' &&
                    this.renderField({ name, labelId: name, inputType: type })
                  }
                </div>
              ))}
            </div>
            <div className='travel-info-form__button-wrapper'>
              <button type='submit' className='btn btn-primary travel-info-form__button travel-info-form__button--submit'
                disabled={loading} data-automation-id='travel-information-form__submit_button'>
                {loading && <Loader overlay white />}
                <span className={loading ? 'invisible' : ''}>
                  <FormattedMessage id={'purchase.purchase_summary.travel_informations.save'} />
                </span>
              </button>
            </div>
          </form>

        </div>
      </>
    )
  }
}

const validatorByName = {
  coming_from: genericComingFromValidation,
  flight_number_arrival: genericFlightNumberArrivalValidation,
  flight_number_departure: genericFlightNumberDepartureValidation,
  meeting_place_arrival: genericMeetingPlaceArrivalValidation,
  meeting_place_departure: genericMeetingPlaceDepartureValidation,
  license_plate: genericLicensePlateValidation,
  passenger_number: genericPassengerNumberValidation,
  vehicle_model: genericVehicleModelValidation,
  real_time_arrival: genericRealTimeArrivalValidation,
  real_time_arrival_airport: genericRealTimeArrivalValidation,
  real_time_arrival_station: genericRealTimeArrivalValidation,
  real_time_arrival_harbour: genericRealTimeArrivalValidation,
  id_passport_number: genericPassportNumberValidation
}

const mapStateToProps = ({
  purchase: { mandatoryInfos, meetingPlaces,
    offer: { beginDate, endDate, offerEndDate, offerBuilder, timezone }, licensePlateRecognition
  },
  railsContext,
  user
}) => ({
  beginDate, endDate, offerEndDate, offerBuilder, timezone, mandatoryInfos, meetingPlaces, railsContext, user, licensePlateRecognition
})

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...PurchaseActions }, dispatch) })

// Circumvents the broken refs issue https://github.com/formatjs/formatjs/issues/1211
const withRouterAndRef = (WrappedComponent) => {
  class InnerComponentWithRef extends React.Component {
    render () {
      const { forwardRef, ...rest } = this.props
      return <WrappedComponent {...rest} ref={forwardRef} />
    }
  }

  const ComponentWithRouter = withRouter(InnerComponentWithRef, { withRef: true })
  return React.forwardRef((props, ref) => {
    return <ComponentWithRouter {...props} forwardRef={ref} />
  })
}

const withIntlAndRef = (Component) => {
  class Wrapper extends React.Component {
    render () {
      const { innerRef, ...props } = this.props
      return (
        <Component
          ref={innerRef}
          {...props} />
      )
    }
  }

  const IntlWrapper = injectIntl(Wrapper)
  return React.forwardRef((props, ref) => (
    <IntlWrapper {...props} innerRef={ref} />
  ))
}

export default withRouterAndRef(withIntlAndRef(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TravelInformationsForm)))
