import React from 'react'
import { slide as Menu } from 'react-burger-menu'

import SideAccount from './SideAccount'
import SideHelp from './SideHelp'
import SideMegaMenu from './SideMegaMenu'
import SideSearch from './SideSearch'
import { SidePro } from './SidePro'
import SideLanguage from './SideLanguage'

export class SideMenu extends React.Component {
  constructor (props) {
    super(props)
    if (props.currentUserName && props.userSpaceLinks) {
      this.userTempArray = props.userSpaceLinks.slice(0, -1)
      this.disconnectLink = props.userSpaceLinks.slice(-1).pop()
    } else {
      this.userTempArray = []
      this.disconnectLink = null
    }
  }

  render () {
    const {
      userSignedIn,
      userFullName,
      userSpaceLinks,
      countryWebsiteLinks,
      openedMenu,
      headerLinks,
      megaMenuContent,
      onSideMenuClose,
      onSearchClick,
      railsContext: { currentLocale }
    } = this.props

    return (
      <Menu
        right
        isOpen={Boolean(openedMenu)}
        width={'85%'}
        customBurgerIcon={false}
        customCrossIcon={false}
        onStateChange={({ isOpen }) => { if (!isOpen) onSideMenuClose(null) }}
      >
        {openedMenu === 'language' &&
          <SideLanguage
            onSideMenuClose={onSideMenuClose}
            countryWebsiteLinks={countryWebsiteLinks} />
        }

        {openedMenu === 'help' &&
          <SideHelp
            onSideMenuClose={onSideMenuClose}
            headerLinks={headerLinks}
            currentLocale={currentLocale} />
        }

        {openedMenu === 'pro' &&
          <SidePro
            onSideMenuClose={onSideMenuClose}
            headerLinks={headerLinks}
            currentLocale={currentLocale} />
        }

        {openedMenu === 'account' &&
          <SideAccount
            onSideMenuClose={onSideMenuClose}
            countryWebsiteLinks={countryWebsiteLinks}
            userSignedIn={userSignedIn}
            userFullName={userFullName}
            userSpaceLinks={userSpaceLinks}
            headerLinks={headerLinks} />
        }

        {openedMenu === 'search' &&
          <SideSearch
            onSideMenuClose={onSideMenuClose}
            onSearchClick={onSearchClick} />
        }

        {openedMenu === 'mega menu' &&
          <SideMegaMenu
            megaMenuContent={megaMenuContent}
            onSideMenuClose={onSideMenuClose}
            headerLinks={headerLinks} />
        }

      </Menu>
    )
  }
}

export default SideMenu
