import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Alert } from 'react-bootstrap'

export class Warning extends Component {
  closeWarning = () => {
    this.props.toggleWarning(false)
  }

  componentDidMount () {
    setTimeout(() => { this.closeWarning() }, 10000)
  }

  render () {
    const { messageId } = this.props

    return (
      <div className='summary-warning'>
        <Alert bsStyle='danger' onDismiss={this.closeWarning} className='summary-warning__danger--orange'>
          <FormattedMessage id={messageId} />
        </Alert>
      </div>
    )
  }
}
