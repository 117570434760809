import React from 'react'
import { FormattedMessage } from 'react-intl'

import CloseIcon from '../../../../../assets/images/icon-close.svg'
import SvgImage from 'Components/SvgImage'
import { datalayerPushNavigationEvent } from '../../helpers/application'

export const SidePro = ({ onSideMenuClose, headerLinks }) => {
  return (
    <div className='side-menu'>
      <div className='side-menu__title'>
        <span> Onepark PRO </span>
        <SvgImage svg={CloseIcon} onClick={() => onSideMenuClose(null)} />
      </div>

      <a className='side-menu__link' href={headerLinks.rentMultipleSpots} onClick={() => datalayerPushNavigationEvent('onepark_pro')}>
        <FormattedMessage id='pages.header.rent_multiple_spots' />
      </a>
      <a className='side-menu__link' href={headerLinks.becomePartner}><FormattedMessage id='pages.header.become_partner' /></a>
      <a className='side-menu__link' href={headerLinks.accessMyPartnerArea}><FormattedMessage id='pages.header.access_my_partner_area' /></a>
    </div>
  )
}
