import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import HomeSearchTabs from './HomeSearchTabs'

import homepageImage from '../../../../assets/images/homepage.webp'
import homepageMobileImage from '../../../../assets/images/homepage-mobile.webp'
import customerRatingDeWebp from '../../../../assets/images/customer-rating-de.webp'
import customerRatingEnWebp from '../../../../assets/images/customer-rating-en.webp'
import customerRatingEsWebp from '../../../../assets/images/customer-rating-es.webp'
import customerRatingFrWebp from '../../../../assets/images/customer-rating-fr.webp'
import customerRatingItWebp from '../../../../assets/images/customer-rating-it.webp'
import customerRatingNlWebp from '../../../../assets/images/customer-rating-nl.webp'
import customerRatingPtWebp from '../../../../assets/images/customer-rating-pt.webp'
import ratingStarWebp from '../../../../assets/images/rating-star.webp'
import ratingStar03Webp from '../../../../assets/images/rating-star-03.webp'
import ratingStar05Webp from '../../../../assets/images/rating-star-05.webp'
import ratingStar06Webp from '../../../../assets/images/rating-star-06.webp'
import ratingStar07Webp from '../../../../assets/images/rating-star-07.webp'

const oneRowIntroductionLocales = ['fr', 'en', 'es', 'nl']
const twoRowsIntroductionLocales = ['de', 'it', 'pt']
const customerRatingValues = {
  'de': 4.6,
  'en': 4.5,
  'es': 4.6,
  'fr': 4.6,
  'it': 4.5,
  'nl': 4.3,
  'pt': 4.7
}
const customerRatingWebp = {
  'de': customerRatingDeWebp,
  'en': customerRatingEnWebp,
  'es': customerRatingEsWebp,
  'fr': customerRatingFrWebp,
  'it': customerRatingItWebp,
  'nl': customerRatingNlWebp,
  'pt': customerRatingPtWebp
}
const customerRatingStarWebp = {
  'de': ratingStar06Webp,
  'en': ratingStar05Webp,
  'es': ratingStar06Webp,
  'fr': ratingStar06Webp,
  'it': ratingStar05Webp,
  'nl': ratingStar03Webp,
  'pt': ratingStar07Webp
}
const customerRatingLinks = {
  'de': 'https://www.echte-bewertungen.com/kundenmeinungen/onepark.co',
  'en': 'https://www.verified-reviews.co.uk/reviews/onepark.co',
  'es': 'https://www.opiniones-verificadas.com/opiniones-clientes/onepark.co',
  'fr': 'https://www.avis-verifies.com/avis-clients/onepark.fr',
  'it': 'https://www.recensioni-verificate.com/recensioni-clienti/onepark.co',
  'nl': 'https://www.echte-beoordelingen.com/klantbeoordelingen/onepark.co',
  'pt': 'https://www.opinioes-verificadas.com/opinioes-clientes/onepark.co '
}

const JumboSearch = ({ intl, searchPopularDestinations, type, locale }) => (
  <section className='react-component jumbo-cover op-search'>
    <img src={homepageImage} className='desktop-background-image' alt={intl.formatMessage({ id: 'pages.homepage.background_image.alt' })} />
    <img src={homepageMobileImage} className='mobile-background-image' alt={intl.formatMessage({ id: 'pages.homepage.background_image.alt' })} />
    <div id='JumboSearch' className='centered-axis-xy jumbo-search'>
      <div className='search--introduction'>
        <div className='search--introduction__text'>
          <h1 className='search__headline'>
            {type === 'subscription' && oneRowIntroductionLocales.includes(locale) &&
              <br />
            }
            {((type === 'package') || twoRowsIntroductionLocales.includes(locale)) && <FormattedMessage id={`pages.homepage.introduction.${type}.headline_first`} /> }
            <FormattedMessage id={`pages.homepage.introduction.${type}.headline_second`} />
          </h1>
          <p className='search__subtitle search__middle-subtitle '>
            <FormattedMessage id={`pages.homepage.introduction.middle_subtitle_first`} />
            <FormattedMessage id={`pages.homepage.introduction.middle_subtitle_second`} />
          </p>
          <p className='search__subtitle search__bottom-subtitle '>
            <FormattedMessage id={`pages.homepage.introduction.bottom_subtitle`} />
          </p>
        </div>
        <div className={`search--introduction__customer-rating${locale === 'pt' ? '-pt' : ''}`}>
          <a href={customerRatingLinks[locale]} target='_blank'>
            <img src={customerRatingWebp[locale]} />
          </a>
        </div>
      </div>

      <HomeSearchTabs />

      <div className='search--popular-destinations'>
        {searchPopularDestinations
          .filter((link) => ['every', type].includes(link.offer_type))
          .map((link, index) =>
            <a key={index} href={link.url}>{link.label}</a>
          )
        }
      </div>

      <div className='customer-rating-mobile'>
        <span className='customer-rating-mobile__star'>
          <img src={ratingStarWebp} />
        </span>
        <span className='customer-rating-mobile__star'>
          <img src={ratingStarWebp} />
        </span>
        <span className='customer-rating-mobile__star'>
          <img src={ratingStarWebp} />
        </span>
        <span className='customer-rating-mobile__star'>
          <img src={ratingStarWebp} />
        </span>
        <span className='customer-rating-mobile__star'>
          <img src={customerRatingStarWebp[locale]} />
        </span>
        <span className='customer-rating-mobile__rating-value'>
          {customerRatingValues[locale]}
        </span>
        <span className='customer-rating-mobile__rating-border'>
          /5
        </span>
        <span className='customer-rating-mobile__reviews'>
          <a href={customerRatingLinks[locale]} target='_blank'>
            <FormattedMessage id={`pages.homepage.customer_rating.reviews`} />
          </a>
        </span>
        <div className='customer-rating-mobile__review-platform'>
          <FormattedMessage id={`pages.homepage.customer_rating.review_platform`} />
        </div>
      </div>
    </div>
  </section>
)

const mapStateToProps = ({
  site: { searchPopularDestinations },
  search: { nextParams: { type } },
  railsContext: { i18nLocale: locale } }
) => ({ searchPopularDestinations, type, locale })

export default injectIntl(connect(mapStateToProps)(JumboSearch))
