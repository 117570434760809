import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import FormInputComponent from './FormInputComponent'
import SvgImage from '../SvgImage'
import iconClose from '../../../../../../app/assets/images/svg-on-react/icon-close.svg'

const inputError = (text, values, id) => (
  <div className='input-error' id={`${id}_error`}>
    <div className='p-4'>
      {typeof text === 'string' && <FormattedMessage id={text} values={values} />}
    </div>
  </div>
)

const inputSuccess = (text, values) => (
  <div className='input-success'>
    <div className='p-4'>
      {typeof text === 'string' && <FormattedMessage id={text} values={values} />}
    </div>
  </div>
)

class FormInput extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    withoutValidate: PropTypes.bool,
    withoutFloatingLabel: PropTypes.bool,
    validateCallback: PropTypes.func,
    clearInputCallback: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool
    ]),
    setCallback: PropTypes.func,
    placeholderKey: PropTypes.string,
    placeholder: PropTypes.string,
    svgIcon: PropTypes.object,
    svgIconLeft: PropTypes.object,
    iconLeft: PropTypes.string,
    errorMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.object
    ]),
    successMessage: PropTypes.string,
    inputValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    searchValue: PropTypes.string,
    required: PropTypes.bool,
    uppercase: PropTypes.bool
  }

  getErrorComponent (id) {
    const { errorMessage } = this.props

    let valuesMessage = {}
    let textMessage = errorMessage

    if (errorMessage instanceof Object) {
      textMessage = errorMessage['key']
      valuesMessage = errorMessage['values']
    }

    return inputError(textMessage, valuesMessage, id)
  }

  getSuccessComponent () {
    return inputSuccess(this.props.successMessage, {})
  }

  render () {
    const { id, type, placeholderKey, placeholder, iconCallback, clearInputCallback, svgIcon, svgIconLeft, iconLeft, required,
      errorMessage, successMessage, children, withoutFloatingLabel, hasFocus, inputValue, searchValue, highlightValidity, disabled } = this.props

    const valuePresent = inputValue || searchValue

    const inputWrapperClasses = classNames('form-group', `${id}_error`, {
      'is-invalid': errorMessage && !disabled,
      'has-icon-right': svgIcon,
      'has-icon-left': svgIconLeft || iconLeft,
      'has-icon-clear': clearInputCallback,
      'input-keyboard-box': (!['select-op', 'phone-input-op'].includes(type)),
      'input-focused': hasFocus,
      'input-keyboard-op--disabled': disabled,
      'input-keyboard-op--valid': !errorMessage && !disabled && highlightValidity && valuePresent,
      'input-keyboard-op--invalid': errorMessage && !disabled
    })

    const inputStatusClasses = classNames('input-icon', 'icon-direction-right', 'icon-fa-status', {
      'hide': !errorMessage || disabled,
      'status-valid': !errorMessage || disabled,
      'status-invalid': errorMessage && !disabled
    })

    const inside = withoutFloatingLabel ? children : (
      <FormattedMessage id={placeholderKey}>
        {(message) => (
          <div>
            <span className={`floating-label ${valuePresent ? 'floating-label--top' : ''}`}>
              { placeholder || message} {required ? ' *' : ''}
            </span>
            <FormInputComponent {...this.props} >
              {children}
            </FormInputComponent>
          </div>
        )}
      </FormattedMessage>
    )

    return (
      <div
        id={`${id}_element`}
        className={`input-keyboard-op ${valuePresent ? 'has-value' : ''}`}
      >
        <div className={inputWrapperClasses}>
          <div className={inputStatusClasses}>
            <i className='fa fa-circle' />
          </div>
          {svgIconLeft && <SvgImage svg={svgIconLeft} className='input-icon icon-direction-left' />}
          {iconLeft && <img src={iconLeft} className='input-icon icon-direction-left' />}
          {svgIcon &&
            <SvgImage svg={svgIcon} className='input-icon icon-direction-right input-icon--action' onClick={iconCallback} />
          }
          {clearInputCallback &&
            <SvgImage svg={iconClose} className='input-icon icon-direction-right input-icon--action' onClick={clearInputCallback} />
          }
          {inside}
        </div>
        {(errorMessage && !disabled) ? this.getErrorComponent(id) : null}
        {successMessage ? this.getSuccessComponent() : null}
      </div>
    )
  }
}

export default FormInput
