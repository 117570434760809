import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import CloseIcon from '../../../../../assets/images/icon-close.svg'
import SvgImage from '../../components/SvgImage'
import { countryFlagIcons } from '../../helpers/countryIconsHelper'

class SideLanguage extends Component {
  render () {
    const { onSideMenuClose, countryWebsiteLinks } = this.props

    return (
      <div className='side-menu'>
        <div className='side-menu__title'>
          <span> <FormattedMessage id='pages.header.language' /> </span>
          <SvgImage svg={CloseIcon} onClick={() => onSideMenuClose(null)} />
        </div>

        {countryWebsiteLinks.links.map(link => (
          <a className='side-menu__link' href={link.url} rel={link.rel} key={link.url}>
            <SvgImage svg={countryFlagIcons[link.country]} />
            <span>{link.label}</span>
          </a>
        ))}
      </div>
    )
  }
}

export default SideLanguage
