import classNames from 'classnames'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import CloseIcon from '../../../../../assets/images/icon-close.svg'
import SvgImage from '../../components/SvgImage'
import { countryFlagIcons } from '../../helpers/countryIconsHelper'

class SideAccount extends Component {

  constructor(props) {
    super(props)

    this.state = {
      areLanguagesOpened: false,
    }
  }

  render() {
    const { onSideMenuClose, countryWebsiteLinks, userSignedIn, userFullName, userSpaceLinks, headerLinks } = this.props
    const { areLanguagesOpened } = this.state

    const accountSectionClasses = classNames({ 'visually-hidden': areLanguagesOpened })
    const languagesMenuTitleClasses = classNames('side-menu__title', {
      'side-menu__title--secondary': !areLanguagesOpened,
      'side-menu__title--menu-item-active': areLanguagesOpened,
    })
    const languagesMenuItemClasses = classNames('side-menu__item', { 'visually-hidden': areLanguagesOpened })
    const languagesLinkClasses = classNames('side-menu__link', { 'visually-hidden': !areLanguagesOpened })

    return (
      <div className='side-menu'>
        {userSignedIn
          ?
          <section id='accountSection' className={accountSectionClasses}>
            <div className='side-menu__title'>
              <span> {userFullName} </span>
              <SvgImage svg={CloseIcon} onClick={() => onSideMenuClose(null)}></SvgImage>
            </div>

            <a className='side-menu__link' href={headerLinks.myProfile}><FormattedMessage id='pages.header.my_profile' /></a>
            {userSpaceLinks // case when user is supervisor or partner
              ?
              userSpaceLinks.map((link, linkIndex) => <a className='side-menu__link' href={link.url} key={linkIndex}>{link.label}</a>)

              :
              <>
                <a className='side-menu__link' href={headerLinks.myBookings}><FormattedMessage id='pages.header.my_bookings' /></a>
                <a className='side-menu__link' href={headerLinks.myPaymentDetails}><FormattedMessage id='pages.header.my_payment_details' /></a>
                <a className='side-menu__link' href={headerLinks.myInvoices}><FormattedMessage id='pages.header.my_invoices' /></a>
              </>
            }

            <a className='side-menu__link side-menu__link--highlighted' data-method='delete' href={headerLinks.signOut}>
              <FormattedMessage id='pages.header.log_out' />
            </a>
          </section>

          :
          <section id='accountSection' className={accountSectionClasses}>
            <div className='side-menu__title'>
              <span> <FormattedMessage id='pages.header.my_account' /> </span>
              <SvgImage svg={CloseIcon} onClick={() => onSideMenuClose(null)}></SvgImage>
            </div>

            <a className='side-menu__link side-menu__link--button' href={headerLinks.signIn}>
              <button className='btn btn-primary'> <FormattedMessage id='pages.header.log_in' /> </button>
            </a>

            <div className='side-menu__link side-menu__link--sign-up'>
              <FormattedMessage id='pages.header.sing_up_question' /> <br />
              <a href={headerLinks.signUp}><FormattedMessage id='pages.header.sing_up' /> </a>
            </div>
          </section>
        }

        <section id='languagesSection'>
          <div className={languagesMenuTitleClasses} onClick={() => this.setState({ areLanguagesOpened: false })}>
            <span> <FormattedMessage id='pages.header.language' /> </span>
            {areLanguagesOpened &&
              <SvgImage svg={CloseIcon} onClick={() => onSideMenuClose(null)}></SvgImage>
            }
          </div>

          <div className={languagesMenuItemClasses} onClick={() => this.setState({ areLanguagesOpened: true })}>
            <SvgImage svg={countryFlagIcons[countryWebsiteLinks.title.country]} />
            <span> {countryWebsiteLinks.title.label}</span>
          </div>

          {countryWebsiteLinks.links.map(link => (
            <a className={languagesLinkClasses} href={link.url} rel={link.rel} key={link.url}>
              <SvgImage svg={countryFlagIcons[link.country]} />
              <span>{link.label}</span>
            </a>
          ))}
        </section>
      </div>
    )
  }
}

export default SideAccount
